var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"editLocation"},[_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t('DetallesLocalizacion')))])]),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Nombre'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Nombre'),"label-for":"location-name"}},[_c('b-form-input',{attrs:{"name":"name","placeholder":_vm.$t('Nombre')},model:{value:(_vm.location.name),callback:function ($$v) {_vm.$set(_vm.location, "name", $$v)},expression:"location.name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('topLocation'),"label-for":"location-parent"}},[_c('v-select',{attrs:{"label":"name","filterable":false,"searchable":false,"options":_vm.selectLocations,"placeholder":_vm.$t('topLocation')},model:{value:(_vm.location.parent),callback:function ($$v) {_vm.$set(_vm.location, "parent", $$v)},expression:"location.parent"}})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Descripcion'),"label-for":"location-description"}},[_c('quill-editor',{model:{value:(_vm.location.description),callback:function ($$v) {_vm.$set(_vm.location, "description", $$v)},expression:"location.description"}})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('p',[_vm._v(_vm._s(_vm.$t('TextClickMapEdit')))]),_c('l-map',{ref:"map",attrs:{"zoom":_vm.zoom,"center":_vm.center},on:{"click":_vm.addMarker}},[_c('l-tile-layer',{attrs:{"url":_vm.url}}),(_vm.marker)?_c('l-marker',{attrs:{"lat-lng":_vm.marker}}):_vm._e()],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Latitud'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Latitud'),"label-for":"location-latitude"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('Latitud'),"readonly":""},model:{value:(_vm.location.latitude),callback:function ($$v) {_vm.$set(_vm.location, "latitude", $$v)},expression:"location.latitude"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Longitud'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Longitud'),"label-for":"location-longitude"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('Longitud'),"readonly":""},model:{value:(_vm.location.longitude),callback:function ($$v) {_vm.$set(_vm.location, "longitude", $$v)},expression:"location.longitude"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Direccion'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Direccion'),"label-for":"location-address"}},[_c('b-form-input',{attrs:{"name":"address","placeholder":_vm.$t('Direccion')},model:{value:(_vm.location.address),callback:function ($$v) {_vm.$set(_vm.location, "address", $$v)},expression:"location.address"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('Enviar'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }