<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="editLocation">
      <b-form
          class="mt-2"
          @submit.prevent="handleSubmit"
          @keydown.enter="handleSubmit"
      >
        <b-row>
          <b-col cols="12">
            <h4 class="mb-2" >{{ $t('DetallesLocalizacion') }}</h4>
          </b-col>
          <b-col sm="6">
            <validation-provider
                #default="{ errors }"
                :name="$t('Nombre')"
                rules="required"
            >
              <b-form-group
                  :label="$t('Nombre')"
                  label-for="location-name"
              >
                <b-form-input
                    v-model="location.name"
                    name="name"
                    :placeholder="$t('Nombre')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <b-form-group
                :label="$t('topLocation')"
                label-for="location-parent"
            >
              <v-select
                  label="name"
                  :filterable="false"
                  :searchable="false"
                  :options="selectLocations"
                  v-model="location.parent"
                  :placeholder="$t('topLocation')"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12">    
            <b-form-group
              :label="$t('Descripcion')"
              label-for="location-description"
            >
              <quill-editor
                v-model="location.description"
              />
            </b-form-group>         
          </b-col>
          <b-col cols="12" class="mb-2" >
            <p>{{ $t('TextClickMapEdit') }}</p>
            <l-map ref="map" :zoom="zoom" :center="center" @click="addMarker" >
              <l-tile-layer :url="url" />
              <l-marker
                  v-if="marker"
                  :lat-lng="marker"
              >
              </l-marker>
            </l-map>
          </b-col>
          <b-col sm="6">
            <validation-provider
                #default="{ errors }"
                :name="$t('Latitud')"
                rules="required"
            >
              <b-form-group
                  :label="$t('Latitud')"
                  label-for="location-latitude"
              >
                <b-form-input
                    v-model="location.latitude"
                    name="reference"
                    :placeholder="$t('Latitud')"
                    readonly
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
                #default="{ errors }"
                :name="$t('Longitud')"
                rules="required"
            >
              <b-form-group
                  :label="$t('Longitud')"
                  label-for="location-longitude"
              >
                <b-form-input
                    v-model="location.longitude"
                    name="reference"
                    :placeholder="$t('Longitud')"
                    readonly
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="12" >
            <validation-provider
                #default="{ errors }"
                :name="$t('Direccion')"
                rules="required"
            >
              <b-form-group
                  :label="$t('Direccion')"
                  label-for="location-address"
              >
                <b-form-input
                    v-model="location.address"
                    name="address"
                    :placeholder="$t('Direccion')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="12" class="text-right" >
            <b-button
              type="submit"
              variant="primary"
              class="mt-2 mr-1"
            >
              {{ $t('Enviar') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BListGroupItem,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { LMap, LMarker, LTileLayer } from "vue2-leaflet"

import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch'
import {Icon, latLng} from "leaflet"
import "leaflet/dist/leaflet.css"
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
})

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    BListGroupItem,
    vSelect,
    LMap,
    LTileLayer,
    LMarker,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      center: latLng(40.2085, -3.713),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 5,
    }
  },
  computed: {
    ...mapGetters({
      location: 'locations/getLocation',
      currentClient: 'clients/getCurrentClient',
      selectLocations: 'locations/getSelectLocations',
    }),
    marker() {
      return latLng(this.location.latitude, this.location.longitude)
    },
  },
  methods: {
    ...mapActions({
      edit: 'locations/edit',
      getLocation: 'locations/getLocation',
      getSelectLocations: 'locations/getSelectLocations',
    }),
    addMarker(event) {
      this.location.latitude = event.latlng.lat
      this.location.longitude = event.latlng.lng
    },
    handleSubmit() {
      this.$refs.editLocation.validate().then(success => {
        if (success) {
          const { id } = this.location
          const formData = this.createFormData()
          if (formData) {
            this.edit({ id, location: formData })
          }
        }
      })
    },
    createFormData() {
      const data = new FormData()
      if (this.location.parent) {
        data.append('parent', this.location.parent.id)
      } else {
        data.append('parent', '')
      }
      if (this.location.childs.length) {
        this.location.childs.forEach(e => {
          data.append('childs[]', e.id)
        })
      } else {
        data.append('childs', '')
      }
      data.append('name', this.location.name)
      data.append('latitude', this.location.latitude)
      data.append('longitude', this.location.longitude)
      data.append('description', this.location.description)
      data.append('address', this.location.address)

      return data
    },
    searchEventHandler(result) {
      this.location.address = result.location.label
    },
  },
  async created() {
    await this.getLocation(this.$route.params.id)
    await this.getSelectLocations({ client: this.currentClient.id})
  },
  mounted() {
    const map = this.$refs.map.mapObject
    const searchControl = new GeoSearchControl({
      provider: new OpenStreetMapProvider(),
      showMarker: false,
      // ... some more options
    })
    map.addControl(searchControl)
    map.on('geosearch/showlocation', this.searchEventHandler)
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.vue2leaflet-map {
  &.leaflet-container {
    height: 450px;
  }
}
</style>
